<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <h1 :class="$style.header">
        {{ mix.name }}
      </h1>
      <a
        download
        :href="mix.mp3"
        @click="handleDownloadLinkClick(mix)"
      >
        <button :class="$style.downloadButton">
          Download
        </button>
      </a>
      <ul :class="$style.tracklist">
        <li
          v-for="track in mix.tracklist"
          :key="track"
        >
          {{ track }}
        </li>
      </ul>
    </div>
    <!--<div :class="$style.mixImageContainer">
      <img
        :class="$style.mixImage"
        :src="mixImage"
      >
    </div>-->
  </div>
</template>

<script>
import Mixes from '@/constants/Mixes';

export default {
  name: 'MixView',

  computed: {
    mix() {
      const { mixId } = this.$route.params;
      return Mixes.find(d => d.mp3 === `${mixId}.mp3`);
    },
    mixImage() {
      try {
        return require(`@/assets/images/mixes/original/${this.mix.image}`);
      } catch (err) {
        try {
          return require(`@/assets/images/mixes/${this.mix.image}`);
        } catch (err2) {
          return '';
        }
      }
    },
  },
};
</script>

<style module lang="scss">
.container {
  background-color: #e0e0e0;
  display: flex;
  min-height: 100vh;
  padding: 25px 5vw;
  text-align: left;
}

.content {
  margin-right: 50px;
}

.mixImageContainer {
  width: 320px;
}

.mixImage {
  width: 320px;
}

.mixImage {
  margin-top: 20px;
}

.header {
  font-size: 34px;
  margin: 20px 0;
}

.downloadButton {
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #555;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-bottom: 10px;
  outline: none;
  padding: 5px 20px;
}

.tracklist {
  font-size: 14px;
  max-width: 100%;
  padding-left: 0;
  width: 400px;

  li {
    list-style-type: none;
    margin-bottom: 1px;
  }
}

@media (max-width: 960px) {
  .mixImageContainer { width: 200px; }
  .mixImage { width: 200px; }
}

@media (max-width: 700px) {
  .container { padding: 20px; }
  .content { margin-right: 0; }
  .mixImageContainer { display: none; }
  .tracklist {
    padding-left: 30px;
    width: auto;

    li { list-style-type: circle; }
  }
}
</style>
